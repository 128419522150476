<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div
        class="
          d-none d-md-flex
          flex-wrap
          col-md-4
          left-area
          text-white
          align-items-end
        "
      >
        <img src="@/assets/img/login.png" alt="" class="img-fluid" />

        <div class="p-5">
          <h2>¡Translado seguro!</h2>
          <p>
            Con Frontliner tu paquete llega a tus manos más rápido. Puedes
            recibir todos tus envios y paquetes de forma segura y puntual.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="login-body">
          <img
            src="@/assets/img/logo-login.png"
            alt=""
            class="img-fluid d-block d-md-none mx-auto"
          />
          <h1 class="text-center d-none d-md-block mb-3">{{ $t("login.title") }}</h1>
          <div
            class="alert alert-danger"
            role="alert"
            v-if="error && !processing"
          >
            <span v-if="error == 'invalid'">
              {{ $t("login.invalid") }}
            </span>
            <span v-else>
              {{ $t("login.error") }}
            </span>
          </div>
          <form class="row" @submit="login">
            <div class="form-group col-12">
              <label for="email" class="font-weight-bold">{{
                $t("login.username")
              }}</label>
              <input
                type="text"
                v-model="auth.email"
                name="email"
                id="email"
                class="form-control"
                required
              />
            </div>
            <div class="form-group col-12">
              <label for="password" class="font-weight-bold">{{
                $t("login.password")
              }}</label>
              <input
                type="password"
                v-model="auth.password"
                name="password"
                id="password"
                class="form-control"
                required
              />
            </div>
            <div class="col-12 mb-2">
              <button
                type="submit"
                :disabled="processing"
                class="btn btn-lg btn-primary w-100 my-3"
              >
                {{ processing ? $t("login.wait") : $t("login.submit") }}
              </button>
            </div>
            <div class="col-12 text-center">
              <label>
                {{ $t("login.createAccount.message") }}
                <router-link :to="{ name: 'register' }">{{
                  $t("login.createAccount.action")
                }}</router-link></label
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.left-area {
  background: var(--primary-color--300);
  min-height: 100vh;
}
.login-body {
  max-width: 25rem;
  margin: 0 auto;
}
.form-group {
  margin-bottom: 1rem;
}
.btn-primary {
  background-color: var(--green-color--500);
  border-color: var(--green-color--500);
}
</style>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "login",
  data() {
    return {
      auth: {
        email: "",
        password: "",
      },
      processing: false,
      error: null,
    };
  },
  methods: {
    ...mapActions({
      signIn: "auth/login",
    }),
    async login(event) {
      event.preventDefault();
      this.processing = true;
      this.error = null;
      await axios.get("/csrf-cookie");
      await axios
        .post("/login", this.auth)
        .then(({ data }) => {
          console.log(data);
          this.signIn();
        })
        .catch(({ response: { data, status } }) => {
          console.error(data.message);
          if (status === 422) {
            this.error = "invalid";
          } else {
            this.error = "other";
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>